<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  import { getCompanies, createCompany } from '@/api/Companies.js'
  import { Company } from "@/models/Company";

  /**
   * Basic-table component
   */
  export default {
    page: {
      title: "Empresas",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader },
    data() {
      return {
        title: "Empresas",
        items: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Empresas",
            active: true,
          },
        ],
        list:[],
        forms: {
          company: new Company(),
          agent: {
            name: null,
            email: null,
            password: null
          }
        },
        params: {
          page: 1,
          limit: 50
        },
        ui:{
          pagination:{
            current_page: 1,
            last_page: 1,
            per_page: 20,
            total: 0
          },
          forms: {
            company: {
              disabled: false
            }
          }
        }
      };
    },
    computed: {
      hasItems: function () {
        return this.list.length > 0
      }
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this
        getCompanies(this.params).then(response => {
          vm.params.page = response.current_page
          vm.params.limit = response.per_page

          vm.ui.pagination.current_page = response.current_page
          vm.ui.pagination.last_page = response.last_page
          vm.ui.pagination.per_page = parseInt(response.per_page)
          vm.ui.pagination.total = response.total

          vm.list = response.data.map(company => {
            return new Company(company)
          })
        })
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
      handleOk(bvModalEvt){
        bvModalEvt.preventDefault()

        this.$refs.observer.validate().then(result => {
          if(result){
            this.createCompany()
            this.$bvModal.hide('modal-company')
          }
        });
      },
      createCompany(){
        let vm = this
  
        if(!this.ui.forms.company.disabled){
          this.ui.forms.company.disabled = true;
  
          this.$awn.async(
            createCompany(this.forms.company, this.forms.agent),
            function (response) {
              vm.list.unshift(new Company(response));
              vm.$awn.success('Operação realizada com sucesso.');
              vm.ui.forms.company.disabled = false;
            },
            function (error) {
              vm.ui.forms.company.disabled = false;
              vm.$awn.warning(error || 'Ocorreu um erro ao excutar a operação. Favor verificar os dados inseridos e tentar novamente.');
            }
          )
        }
      }
    },
    watch: {
      "ui.pagination.current_page": function (value) {
        this.params.page = value
        this.fetchData()
      }
    }
  };
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>CPF/CNPJ</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!hasItems">
                  <td colspan="100%" class="text-center">
                    <p class="py-4 mb-0">Nenhum resultado encontrado.</p>
                  </td>
                </tr>
                <tr v-else v-for="item in list" v-bind:key="item.id">
                  <th scope="row">
                    {{ item.id }}
                  </th>
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.nif }}</td>
                  <td class="min">
                    <button
                      type="button"
                      class="btn btn-light btn-sm btn-rounded"
                      @click="$router.push(`/companies/${item.id}`)"
                    >
                      <i class="mdi mdi-eye" />
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
  
              <b-pagination
                :total-rows="ui.pagination.total"
                :per-page="ui.pagination.per_page"
                v-model="ui.pagination.current_page"
                prev-text="Anterior"
                next-text="Próxima"
                align="center"
                class="mt-3 mb-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  
    <b-button
      variant="primary"
      size="lg"
      class="btn-rounded-fixed"
      pill
      @click="$bvModal.show('modal-company')">
      <i class="bx bx-plus"></i>
    </b-button>
  
    <b-modal
      id="modal-company"
      centered
      title="Adicionar Empresa"
      title-class="font-18"
      :cancel-title="'Cancelar'"
      :ok-title="'Salvar'"
      @ok="handleOk"
      size="lg"
    >
    
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form
          @submit.stop.prevent="handleSubmit(handleOk)"
          class="form-horizontal"
        >
          <div class="row">
            <div class="col-md-6">
              <validation-provider
                name="Nome Fantasia"
                :rules="{ required: true }"
                v-slot="name"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="Nome Fantasia"
                  label-for="input-company-name"
                >
                  <b-form-input
                    v-model="forms.company.name"
                    id="input-company-name"
                    name="name"
                    :state="getValidationState(name)"
                  />
                  <b-form-invalid-feedback>{{ name.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          
            <div class="col-md-6">
              <validation-provider
                name="Razão Social"
                :rules="{ required: true }"
                v-slot="business_name"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="Razão Social"
                  label-for="input-company-business-name"
                >
                  <b-form-input
                    v-model="forms.company.business_name"
                    id="input-company-business-name"
                    name="business_name"
                    :state="getValidationState(business_name)"
                  />
                  <b-form-invalid-feedback>{{ business_name.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          
            <div class="col-md-6">
              <validation-provider
                name="CPF/CNPJ"
                :rules="{ required: true, min: 11, max: 14, nif: true }"
                v-slot="nif"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="CPF/CNPJ"
                  label-for="input-company-nif"
                >
                  <b-form-input
                    id="input-company-nif"
                    v-model="forms.company.nif"
                    name="nif"
                    v-mask="'##############'"
                    :state="getValidationState(nif)"
                  />
                  <b-form-invalid-feedback>{{ nif.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          
            <div class="col-md-6">
              <validation-provider
                name="E-mail"
                :rules="{ required: true, email: true }"
                v-slot="email"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="E-mail"
                  label-for="email"
                >
                  <b-form-input
                    for="email"
                    v-model="forms.company.email"
                    id="input-company-email"
                    name="email"
                    :state="getValidationState(email)"
                  />
                  <b-form-invalid-feedback>{{ email.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          
            <div class="col-md-6">
              <validation-provider
                name="Telefone"
                :rules="{ numeric: true }"
                v-slot="phone"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="Telefone"
                  label-for="phone"
                >
                  <b-form-input
                    v-model="forms.company.phone"
                    id="input-company-phone"
                    name="phone"
                    v-mask="'############'"
                  />
                  <b-form-invalid-feedback>{{ phone.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
        
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="Logradouro"
                label-for="input-company-street"
              >
                <b-form-input
                  v-model="forms.company.street"
                  id="input-company-street"
                  name="street"
                />
              </b-form-group>
            </div>
          
            <div class="col-md-6">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="Número"
                label-for="number"
              >
                <b-form-input
                  v-model="forms.company.number"
                  id="input-company-number"
                />
              </b-form-group>
            </div>
          
            <div class="col-md-6">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="Complemento"
                label-for="complement"
              >
                <b-form-input
                  v-model="forms.company.complement"
                  id="input-company-complement"
                />
              </b-form-group>
            </div>
          
            <div class="col-md-6">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="Bairro"
                label-for="district"
              >
                <b-form-input
                  v-model="forms.company.district"
                  id="input-company-district"
                />
              </b-form-group>
            </div>
          
            <div class="col-md-6">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="Cidade"
                label-for="city"
              >
                <b-form-input
                  v-model="forms.company.city"
                  id="input-company-city"
                />
              </b-form-group>
            </div>
          
            <div class="col-md-6">
              <validation-provider
                name="UF"
                :rules="{ alpha: true, min: 2, max: 2 }"
                v-slot="uf"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="UF"
                  label-for="uf"
                >
                  <b-form-input
                    v-model="forms.company.uf"
                    id="input-company-uf"
                    name="uf"
                    v-mask="'AA'"
                    :state="getValidationState(uf)"
                  />
                  <b-form-invalid-feedback>{{ uf.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          
            <div class="col-md-6">
              <validation-provider
                name="CEP"
                :rules="{ min: 9, max: 9 }"
                v-slot="zipcode"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="CEP"
                  label-for="zipcode"
                >
                  <b-form-input
                    v-model="forms.company.zipcode"
                    id="input-company-zipcode"
                    v-mask="'######-###'"
                    name="zipcode"
                    :state="getValidationState(zipcode)"
                  />
                  <b-form-invalid-feedback>{{ zipcode.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          
            <div class="col-md-6">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="País"
                label-for="country"
              >
                <b-form-input
                  v-model="forms.company.country"
                  id="input-company-country"
                />
              </b-form-group>
            </div>
          </div>
          
          <h3 class="font-size-14 mt-4 mb-3">Dados do Agente</h3>
  
          <div class="row">
            <div class="col-md-6">
              <validation-provider
                name="Nome"
                :rules="{ required: true }"
                v-slot="name"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="Nome"
                  label-for="input-agent-name"
                >
                  <b-form-input
                    v-model="forms.agent.name"
                    id="input-agent-name"
                    name="name"
                    :state="getValidationState(name)"
                  />
                  <b-form-invalid-feedback>{{ name.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
  
            <div class="col-md-6">
              <validation-provider
                name="E-mail"
                :rules="{ required: true, email: true }"
                v-slot="email"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="Email"
                  label-for="input-agent-email"
                >
                  <b-form-input
                    v-model="forms.agent.email"
                    id="input-agent-email"
                    type="email"
                    name="email"
                    :state="getValidationState(email)"
                  />
                  <b-form-invalid-feedback>{{ email.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
  
            <div class="col-md-6">
              <validation-provider
                name="Senha"
                :rules="{ required: true, min: 6 }"
                v-slot="password"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="Senha"
                  label-for="input-agent-password"
                >
                  <b-form-input
                    v-model="forms.agent.password"
                    id="input-agent-password"
                    type="password"
                    name="password"
                    :state="getValidationState(password)"
                  />
                  <b-form-invalid-feedback>{{ password.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
        
          <template v-slot:modal-footer>
            <b-button size="sm" variant="success" type="submit">
              Salvar
            </b-button>
            <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-company')">
              Cancelar
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </Layout>
</template>
